
  import { defineComponent, ref, computed, getCurrentInstance } from "vue";
  import Tile from "@/components/Tile.vue"
  import { Lead } from "@/models/leads"
  import { Ticket } from "@/models/tickets"
  import { Task } from "@/models/tasks"
  import { Tile as TileModel } from "@/models/dashboard"
  import { Column } from "@/models/table";
  import apiClient from '@/api/apiClient'
  import { getRefreshData, initializeModal, addDefaultTaskColumns } from "@/view_scripts/dashboardUtils";
  import { Data, Props, TileAction } from "@/views/shared/dashboard/dashboard.interface";
  import { addAction } from "@/view_scripts/dashboardUtils";
  import { stringSort } from "@/utils/helper";
  import { getColumns as iLDupcliateColumns } from "./dashboard/i_l_duplicate";
  import { getColumns as iLNewColumns } from "./dashboard/i_l_new";
  import { getColumns as iLReclaimedColumns } from "./dashboard/i_l_reclaimed";
  import { getColumns as iCrNew } from "./dashboard/i_cr_new";
  import { getColumns as iPNosugColumns } from "./dashboard/i_p_nosug";
  import { getColumns as iPActiveColumns } from "./dashboard/i_p_active";
  import { getColumns as iTNotified } from "./dashboard/i_t_notified";
  import { getColumns as iPBroadcastColumns } from "./dashboard/i_p_broadcast";
  import DuplicateModal from "./dashboard/modals/DuplicateModal.vue"
  import TicketModal from "./dashboard/modals/TicketModal.vue"
  import CareReceiverAssignModal from "./dashboard/modals/CareReceiverAssignModal.vue"
  import { lastTicketActivity, fullName } from "@/utils/dataExtractors";

  export default defineComponent({
    components: { Tile },
    props: { baseData: Array },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      //Tiles
      const activeTile = ref<TileModel>()
      const setActiveTile = (tile: TileModel) => {
        activeTile.value = tile
        processTileAction()
      }
      const processTileAction = async () => {
        const tile = activeTile.value
        const response = await apiClient.get(tile?.links[tile.sysId])
        response.data.data = stringSort(response.data.data, (e) => Date.parse(e.attributes.createdAt), false)
        updateTableItems(response.data)
      }

      // Table
      const showTable = ref(false)
      const tableName = ref()
      const columns = ref<Column[]>([])
      const modalColumns = ref<Column[]>([])
      const tableItems = ref<any[]>([])
      const refreshData = getRefreshData()

      const update = () => {
        refreshData()
        processTileAction()
      }

      //Extra Table
      const sellerLeads = ref<any>([])
      const leadSourceIdx = ref<any[]>([])
      const iLNewTable = () => {
        const dt = new Date();
        const todayString = `${dt.getFullYear()}-${dt.getMonth()+1}-${dt.getDate()}`
        const leadQueryParams = {
          'filter[created_at.gte]':todayString,
          'filter[seller_id.ne]':'nil',
          'page[size]': -1,
          'include': 'seller,protoLeadsource',
          'fields[sellers]': 'firstName,lastName',
          'fields[protoLeadsources]': 'sysId',
        }
        root.$store.dispatch('leads/loadv2', leadQueryParams).then((r) => {
          const leads = r.data.data
          const sellers = r.data.included.filter((r) => r.type=='sellers')
          leadSourceIdx.value = r.data.included.filter((r) => r.type=='proto_leadsources').reduce((result, r) => {
            result[r.id] = r
            return result
          }, {})
          const fields = [
            {
              key: 'sellerName',
              sortable: true,
              label: 'VP Name'
            },
            {
              key: 'assignedHP',
              sortable: true,
              label: 'ID HP'
            },
            {
              key: 'assignedBroker',
              sortable: true,
              label: 'ID Broker'
            },
            {
              key: 'purchasedHP',
              sortable: true,
              label: 'VP HP'
            },
            {
              key: 'purchasedBroker',
              sortable: true,
              label: 'VP Broker'
            },
            {
              key: 'selfCreated',
              sortable: true,
              label: 'VP Erstellt'
            },
            {
              key: 'inbound',
              sortable: true,
              label: 'inbound'
            },
            {
              key: 'total',
              sortable: true,
              label: 'Total'
            },
          ]
          const items = sellers.reduce((result, seller) => {
            const sellerName = fullName(seller)
            const sellerLeads = leads.filter((lead) => lead.relationships?.seller?.data?.id === seller.id)
            const inbound = sellerLeads.filter((lead) => leadSourceIdx.value[lead.relationships.protoLeadsource.data.id]?.attributes.sysId.startsWith('ls_0800'))
            const nonInbound = sellerLeads.filter((lead) => !inbound.includes(lead))
            const selfCreated = nonInbound.filter((lead) => lead.relationships.creator.data.id === seller.id)
            const systemCreated = nonInbound.filter((lead) => lead.relationships.creator.data.id != seller.id)
            const purchased = systemCreated.filter((lead) => lead.attributes.purchased)
            const assigned = systemCreated.filter((lead) => !lead.attributes.purchased)
            const purchasedHP = purchased.filter((lead) => lead.attributes.price >= 70)
            const purchasedBrokerCount = purchased.length - purchasedHP.length
            const assignedHP = assigned.filter((lead) => lead.attributes.price >= 70)
            const assignedBrokerCount = assigned.length - assignedHP.length
            const row = {
              sellerName: sellerName,
              assignedHP: assignedHP.length,
              assignedBroker: assignedBrokerCount,
              purchasedHP: purchasedHP.length,
              purchasedBroker: purchasedBrokerCount,
              selfCreated: selfCreated.length,
              inbound: inbound.length,
              total: sellerLeads.length
            }
            result.push(row)
            return result
          },[])
          sellerLeads.value = {fields, items}
        })
      }
      iLNewTable()

      // Modal
      const { modalType, modalProps, addReminderAction, addDestroyTaskAction } = initializeModal(root, update)
      const modalTableItems = ref<any[]>([])
      const modalClosed = (reload?: boolean) => {
        modalProps.value = { visible: false }
        modalType.value = undefined
        if (reload) {
          refreshData()
          processTileAction()
        }
      }

      // const careReceiver = ref<any>({})
      // const crLeadId = ref<string>('')



      const updateTableItems = (
        data: Data
      ) => {
        let cols: any
        let nextAction
        let sortGetter
        switch (activeTile.value?.sysId) {
          case 'i_t_notified':
            tableName.value = 'Tickets'
            tableItems.value = stringSort((data.data as any[]), (i) => lastTicketActivity(i.attributes.taskable), false)
            nextAction = async (task: Task<Ticket>, action: string) => {
              modalType.value = TicketModal
              modalProps.value = {
                visible: true,
                action: action,
                ticket: task.attributes.taskable
              }
            }
            cols = iTNotified(nextAction)
            addDestroyTaskAction(cols)
            addReminderAction(cols)
            break
          case 'i_l_new':
            tableName.value = 'Leads ohne Vertriebler (Keine Dubletten)'
            tableItems.value = (data.data as Task<Lead>[])
            data.sellers = data.sellers.sort((a, b) => a.fullName.localeCompare(b.fullName))
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = iLNewColumns(data as any, nextAction)
            break
          case 'i_l_reclaimed':
            tableName.value = 'Erhaltene Reklamationen'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = iLReclaimedColumns(nextAction)
            break
          case 'i_l_duplicate':
            tableName.value = 'Lead Dubletten'
            sortGetter = (e) => Date.parse(e.attributes.taskable.attributes.createdAt)
            tableItems.value = stringSort(data.data, sortGetter, false)
            nextAction = (item: any, dupLeads: any[]) => {
              modalType.value = DuplicateModal
              modalProps.value = {
                visible: true,
                itemToCheck: item,
                modalTableItems: dupLeads
              }
            }
            cols = iLDupcliateColumns(nextAction)
            break
          case 'i_p_nocs':
            tableName.value = 'Stellenausschreibungen ohne Vorschläge'
            tableItems.value = (data.data)
            nextAction = (item: any, dupLeads: any[]) => {
              refreshData()
              processTileAction()
            }
            cols = iPNosugColumns(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 'i_p_broadcast':
            tableName.value = 'Schwere oder dringende Fällt'
            tableItems.value = (data.data)
            nextAction = (item: any, dupLeads: any[]) => {
              refreshData()
              processTileAction()
            }
            cols = iPBroadcastColumns(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 'i_p_active':
            tableName.value = 'Aktive Stellenausschreibungen (keine Wechsel)'
            tableItems.value = (data.data)
            cols = iPActiveColumns()
            break
          case 'i_cr_new':
            tableName.value = 'Erfassungsbögen ohne Lead'
            tableItems.value = (data.data)
            nextAction = async (item: any) => {
              const leadMatchesHref = item.attributes.taskable.links.lead_matches
              const leadMatchesResponse = await apiClient.get(leadMatchesHref)
              modalProps.value = {
                visible: true,
                tableItems: leadMatchesResponse.data,
                careReceiver: item.attributes.taskable
              }
              modalType.value = CareReceiverAssignModal
              // crToLeadModalVisible.value = true
              // modalTableItems.value = leadMatchData
              // modalColumns.value ({ modalRowAction: modalRowAction, data: leadMatchData })
            }
            cols = iCrNew(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          default:
            throw Error("This Tile Action does not exist.")
        }
        columns.value = cols
        showTable.value = true
      }

      const titleActions = ref([
        {
          title: 'Dokument',
          icon: 'pencil-alt',
          tooltip: 'Dokument tooltip',
          children: [
            {
              title: 'Bearbeiten',
              icon: 'pencil-alt',
              tooltip: 'Bearbeiten tooltip',
              children: [
                {
                  title: 'Nested',
                  icon: 'pencil-alt',
                  onClick: () => alert("Nested clicked")
                }
              ]
            },
            {
              title: 'Archivieren',
              icon: 'archive',
              tooltip: 'Archivieren tooltip',
              onClick: () => alert('Dokument archivieren')
            },
            {
              title: 'Reklamieren',
              icon: 'exclamation-triangle',
              onClick: () => alert('Lead reklamieren')
            }
          ]
        },
        {
          title: 'Vertrieb',
          icon: 'address-book',
          tooltip: 'Vertrieb tooltip',
          onClick: () => alert('Vertrieb verwalten')
        },
      ])

      return {
        activeTile,
        setActiveTile,
        tableName,
        tableItems,
        showTable,
        columns,
        modalType,
        modalProps,
        modalClosed,
        modalColumns,
        modalTableItems,
        // careReceiver,
        // crLeadId,
        // launchConfirm,
        titleActions,
        sellerLeads
      }
    }
  })
