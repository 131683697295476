
  import { defineComponent, ref, computed, watch, reactive, getCurrentInstance } from 'vue'
  import { providerCareStayColumns as careStayColumns } from '@/view_scripts/table_columns/care_stay_columns'
  import { CareStay } from '@/models/care_stays'
  import SchemaForm from '@/components/shared/form/SchemaForm.vue'
  import { careStaySchema, providerUiCareStaySchema as uiCareStaySchema } from '@/view_scripts/form_schemas/care_stay'
  import { getRefreshData } from '@/utils/refreshData'
  import { confirm, prompt } from '@/utils/interactionModals'
  import CareStayPricingInfo from './CareStayPricingInfo.vue'

  export default defineComponent({
    components: {
      SchemaForm,
      CareStayPricingInfo
    },
    props: { items: { type: Array, required: true } },
    setup(props: any) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const editCareStayModal = ref()
      const monthlyPriceModal = ref()
      const availableStages = ['Neu', 'Vorgestellt', 'Angenommen', 'Abgebrochen', 'Bestätigt', 'Alle']
      const stage = ref('Alle')
      const filteredCareStays = computed(() => props.items.filter(cs => {
        return (stage.value === 'Alle' || cs.attributes.stage === stage.value)
      }))
      const selectedCareStay = ref()
      const csActionModal = ref()
      const careStayToEdit = ref<ResourceObject<string, CareStay>>()
      const refreshData = getRefreshData()
      const csActionData = reactive<{[key: string]: any}>({
        usecase: null,
        reasons: [],
        wrongstate: false,
        customReason: '',
        selectedReason: null,
        title: 'Default Titel',
        billStart: null,
        billEnd: null,
        arrival: null,
        departure: null,
        action: () => {alert('es wurde keine Aktion festgelegt')}
      })
      const resetCsActionData = () => {
        csActionData.reasons = []
        csActionData.wrongstate = false
        csActionData.customReason = ''
        csActionData.selectedReason = null
        csActionData.title = 'Default Titel'
        csActionData.billStart = null
        csActionData.billEnd = null
        csActionData.arrival = null
        csActionData.departure = null
        csActionData.selectedReason = null
      }

      const showMonthlyPrice = (data: any) => {
        resetCsActionData()
        csActionData.usecase = 'cs_confirm'
        csActionData.title = 'Monatspreis'
        csActionData.action = (careStay: any) => {}
        csActionModal.value.show()
      }

      const editCareStay = (data: any) => {
        resetCsActionData()
        csActionData.usecase = 'cs_confirm'
        csActionData.title = 'Pflegeeinsatz bearbeiten'
        csActionData.action = (careStay: any) => {}
        csActionModal.value.show()
      }
      
      const confirmCareStay = (data: any) => {
        resetCsActionData()
        csActionData.usecase = 'cs_confirm'
        csActionData.title = 'Pflegeeinsatz bestätigen'
        csActionData.action = (careStay: any) => {}
        csActionModal.value.show()
      }

      const cancelCareStay = async (data: any) => {
        resetCsActionData()
        csActionData.usecase = 'cs_confirm'
        csActionData.title = 'Pflegeeinsatz abbrechen'
        csActionData.action = (careStay: any) => {}
        csActionModal.value.show()
      }

      const getReasons = async (sysId: string) => {
        const listQuery = {'filter[sys_id]': sysId, include: 'sublists'}
        showLoading.value = true
        await root.$store.dispatch('lists/load', listQuery)
        const reasons = root.$store.state.lists.data[0].attributes.sublists
          .map((rr)=>{ return {value: rr.id, text: rr.attributes?.name} })
          .concat({value: 'new', text: "Eigenen Grund angeben..."})
        showLoading.value = false
        return reasons
      }


      const isCancelReasonSelected = computed(() => {
        return csActionData.usecase === 'cs_cancel' && csActionData.selectedReason !== null
      })


      const careStayActionColumns = ref(careStayColumns({showMonthlyPrice, editCareStay, confirmCareStay, cancelCareStay}))
      const saveCareStay = async (bvModalEvent: any) => {
        // bvModalEvent.preventDefault()
        // editCareStayModal.value.hide()
        showLoading.value = true
        root.$store.dispatch('care_stays/edit', { id: careStayToEdit.value.id, body: careStayToEdit.value })
          .then(refreshData)
          .then((r) => showLoading.value = false)
        root.$nextTick(() => { csActionModal.value.hide() })
      }
      return {
        showLoading,
        monthlyPriceModal,
        editCareStayModal,
        csActionModal,
        careStaySchema,
        uiCareStaySchema,
        saveCareStay,
        careStayToEdit,
        careStayActionColumns,
        selectedCareStay,
        csActionData,
        filteredCareStays,
        stage,
        availableStages,
        isCancelReasonSelected
      }
    }
  })
